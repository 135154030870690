import React from "react";
import logo from '../src/assets/logo.png';

const Header = () => (
  <div className="absolute flex gap-2 sm:gap-4 top-0 w-full h-fit bg-[#ff8600] text-white px-4 sm:px-10 py-4">
    <img src={logo} className="w-8 h-8 sm:w-10 sm:h-10" alt="Cyclo Technologies" />
    <p className="uppercase text-black text-xl sm:text-2xl mt-1">Cyclo Technologies</p>
  </div>
);

const MainContent = () => (
  <div className="flex gap-4 flex-col items-center justify-center w-full p-4">
    <h2 className="font-bold text-lg sm:text-[32px] leading-6 sm:leading-[38px] text-[#ff8600] text-center">
      Cyclo Technologies is excited to introduce a new version of the database and our new brand available at <a href="https://nucleonspace.com"> nucleonspace.com </a>
    </h2>
    <hr className="h-[1px] bg-white w-10/12" />
    <span className="font-semibold text-base sm:text-xl text-center text-white">
      Please visit the new site to continue to access the database and learn more about our radiation testing and engineering services.
    </span>
    <CallToAction />
    <ContactInfo />
  </div>
);

const CallToAction = () => (
  <button
    onClick={() => window.open("https://nucleonspace.com", "_blank")}
    className="px-6 py-2 rounded-md bg-[#ff8600] text-white font-bold transition duration-200 hover:bg-white hover:text-[#ff8600] border-2 border-transparent hover:border-[#ff8600]"
  >
    nucleonspace.com
  </button>
);

const ContactInfo = () => (
  <p className="text-white text-sm sm:text-base text-center">
    For inquiries please email{" "}
    <a href="mailto:info@cyclotechnologies.com" className="underline decoration-[#ff8600] text-[#ff8600]">
      info@cyclotechnologies.com
    </a>
  </p>
);

const Footer = () => (
  <div className="flex flex-wrap w-full items-center justify-center absolute bottom-0 text-black bg-[#ff8600] text-xs sm:text-base py-2">
    Copyright ©{" "}
    <a href="https://cyclotechnologies.com/" className="mr-2">
      Cyclo Technologies, Inc
    </a>
    2024. All Rights Reserved.
  </div>
);

const App = () => {
  return (
    <div className="relative flex flex-col h-screen w-full bg-black">
      <Header />
      <div className="flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black w-full max-w-screen p-2 sm:p-0">
        <MainContent />
      </div>
      <Footer />
    </div>
  );
};

export default App;